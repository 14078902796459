
import { useRoute, useRouter } from 'vue-router';
import { ref, reactive, onMounted } from 'vue';
import { ChevronRightIcon, PencilIcon } from '@heroicons/vue/outline';
import { XCircleIcon, CheckIcon, XIcon } from '@heroicons/vue/solid';
import SystemStatusBadge from '@/components/SystemStatusBadge.vue';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import { useForm } from 'vee-validate';
import { object, string, array } from 'yup';
import TextField from '@/components/TextField.vue';
import FormSwitch from '@/components/FormSwitch.vue';
import availableRoles from '@/constants/availableRoles';
import { parseRole } from '@/components/utils';
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue';
import { getUser, updateUser, deleteUser } from '@/api/users.api';

export default {
  components: {
    ChevronRightIcon,
    PencilIcon,
    CheckIcon,
    XIcon,
    SystemStatusBadge,
    XCircleIcon,
    TextField,
    FormSwitch,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
  },
  setup(): Record<string, unknown> {
    const route = useRoute();
    const router = useRouter();

    const error = ref('');
    const loading = ref(false);
    const user = reactive({
      email: '',
      password: 'Fake_password_to_avoid_form_error_123',
      roles: [],
    });
    const allowEditing = ref(false);

    const isOpen = ref(false);

    const closeModal = () => {
      isOpen.value = false;
    };

    const openModal = () => {
      isOpen.value = true;
    };

    const schema = object({
      email: string()
        .email('Invalid email value')
        .required('Email is required'),
      password: string()
        .min(16, 'Password length needs to be at least 16 character long')
        .matches(
          /^(?=.*[a-z])/,
          'Password must contain at least one lowercase character'
        )
        .matches(
          /^(?=.*[A-Z])/,
          'Password must contain at least one uppercase character'
        )
        .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
        .notRequired(),
      roles: array().of(string().required()),
    });

    const { handleSubmit, setValues } = useForm({
      validationSchema: schema,
      initialValues: user,
    });

    const setFormValues = (values: Record<string, any>) => {
      const formValues = {
        email: values.email,
        password: 'Fake_password_to_avoid_form_error_123',
        roles: values.roles,
      };
      setValues(formValues);
    };

    const editUser = handleSubmit(async (values: any) => {
      // Handle user data update
      try {
        if (
          'Fake_password_to_avoid_form_error_123'?.includes(values.password)
        ) {
          delete values.password;
        }

        const body = {
          ...values,
        };

        for (const [key, value] of Object.entries(values)) {
          if (
            (user[key as 'email' | 'password' | 'roles'] as string) ===
            (value as string)
          ) {
            delete body[key];
          }
        }
        const userId = route.params.id as string;

        const updatedUser = (await updateUser(userId, body)).data;
        Object.assign(user, updatedUser);
        setFormValues(updatedUser);
        allowEditing.value = false;
      } catch (e) {
        error.value = 'Something went wrong updating the user';
      }
    });

    const removeUser = async () => {
      // Add logic for user removal.
      try {
        const userId = route.params.id as string;
        await deleteUser(userId);
        closeModal();
        router.replace({ path: '/users' });
      } catch (e) {
        error.value = 'Something went wrong deleting the user';
      }
    };

    const fetchUser = async (id: string) => {
      try {
        const fetchedUser = (await getUser(id)).data;
        Object.assign(user, fetchedUser);
        setFormValues(fetchedUser);
      } catch (e) {
        error.value = 'Error fetching user';
      }
    };

    onMounted(() => {
      const userId = route.params.id as string;
      fetchUser(userId);
    });

    return {
      error,
      loading,
      user,
      allowEditing,
      availableRoles,
      parseRole,
      schema,
      isOpen,
      openModal,
      closeModal,
      removeUser,
      editUser,
    };
  },
};

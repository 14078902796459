
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';
import { Switch } from '@headlessui/vue';

export default defineComponent({
  components: {
    Switch,
  },
  props: {
    modelValue: {
      type: null,
    },
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    unavailable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    toggleColor() {
      if (this.unavailable) {
        return 'bg-gray-100';
      }
      if (this.checked) {
        return 'bg-green-400';
      }
      return 'bg-green-200';
    },
  },
  setup(props: any) {
    const { checked, handleChange } = useField(props.name, undefined, {
      type: 'checkbox',
      checkedValue: props.value,
    });

    return {
      checked,
      handleChange,
    };
  },
});

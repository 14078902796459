import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Switch = _resolveComponent("Switch")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Switch, {
      model: _ctx.checked,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleChange(_ctx.value))),
      disabled: _ctx.disabled,
      class: _normalizeClass([_ctx.toggleColor, "relative inline-flex flex-shrink-0 h-6 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"])
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          "aria-hidden": "true",
          class: _normalizeClass([_ctx.checked ? 'translate-x-6' : 'translate-x-0', "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200"])
        }, null, 2)
      ]),
      _: 1
    }, 8, ["model", "disabled", "class"])
  ]))
}